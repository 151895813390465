import styled from 'styled-components';
import { Tablet } from '../../../atoms/_Variable/var';

export const MOBILE_BREAK_POINT = 1110;

export const Wrapper = styled.section`
	margin-top: 80px;
	padding-top: 6rem;
	padding-bottom: 2.813rem;
	background-color: #f9fbff;
	@media only screen and (max-width: ${Tablet}) {
		padding-bottom: 12.875rem;
	}

	.topIndex {
		z-index: 9;
		position: relative;
	}

	.textWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding-left: 20%;
		padding-right: 20%;
		@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			margin-bottom: 3.313rem;
		}
		.bannerImg {
			width: 221px;
			margin-bottom: 2.75rem;
		}
		.pDefault {
			margin-top: 1.25rem;
		}
		@media only screen and (max-width: 1400px) {
			padding-left: 5%;
			padding-right: 5%;
		}
		@media only screen and (max-width: ${Tablet}) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.rowPriceList {
		margin-top: 3.125rem;
		@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			display: none;
		}
	}

	.list-on-mobile {
		display: none;
		@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			display: block;
		}
		.container-mobile {
			@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
				padding-left: 0;
				padding-right: 0;
				margin-left: 0;
				max-width: 100%;
			}
		}
	}

	.price-list-item {
		@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			&:not(:first-child) {
				margin-top: 1.25rem;
			}
		}
	}

	.quarterCircle {
		background-image: url(https://seiturju.sirv.com/Chatbiz/homepage/QuarterCircle.svg);
		width: 300px;
		height: 361px;

		transform: scale(1);
		position: absolute;
		top: 82px;
		right: 0;
		background-repeat: no-repeat;
		z-index: 1;

		transform-origin: right top;
		@media only screen and (max-width: ${Tablet}) {
			transform: scale(0.6);
			top: 60px;
		}
	}
`;

export default Wrapper;
