import styled from 'styled-components';
import { Large } from '../../../atoms/_Variable/var';
import { MOBILE_BREAK_POINT } from '../../../organisms/v2/Pricing/style';

export const Wrapper = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 50px rgba(126, 169, 210, 0.15);
	border-radius: 8px;
	z-index: 99;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 1.25rem 0;
	position: relative;
	height: 550px;
	@media only screen and (max-width: 1316px) {
		height: 620px;
	}
	/* @media only screen and (max-width: 1200px) {
		height: 620px;
	} */
	@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
		height: auto;
		padding-bottom: 0;
	}
	@media only screen and (min-width: ${Large}) {
		height: 630px;
	}

	.price-header {
		width: 100%;
		@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
		}
		&__right {
			display: none;
			@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				padding-right: 1.5rem;
				.txt-start-from {
					padding-top: 0.5rem;
				}
				.txt-price {
					margin-top: 0.5rem;
				}
				.btn-right-wrapper {
					margin-top: 1.25rem;
					.btnPrimaryDefault {
						display: inline-block;
						padding-left: 0 !important;
						padding-right: 0 !important;
						width: 169px;
						text-align: center;
					}
				}
			}
		}
		&__left {
			@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
				width: 50%;
			}
		}
	}

	.bottom-wrapper {
		@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			display: none;
		}
	}

	.price-title,
	.price-sub {
		text-align: center;
		align-self: center;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			text-align: left;
			align-self: flex-start;
		}
	}

	.price-sub {
		margin-top: 0.313rem;
		height: 50px;
		@media only screen and (max-width: 1316px) {
			height: 80px;
		}
		@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			height: auto;
		}
	}
	ul {
		list-style: none;
		margin: 0.938rem 0 0 0;
		padding: 1.25rem 1.5rem;
		background-color: rgba(187, 221, 230, 0.1);
		min-height: 245px;
		width: 100%;
		@media only screen and (max-width: 1316px) {
			min-height: 300px;
		}
		/* @media only screen and (max-width: 1200px) {
			min-height: 300px;
		} */
		@media only screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			min-height: auto;
		}

		@media only screen and (min-width: ${Large}) {
			min-height: 350px;
		}

		li {
			display: flex;
			&:not(:first-child) {
				margin-top: 0.563rem;
			}

			img {
				width: 11.75px;
				height: 11.75px;

				margin-right: 0.946rem;
				margin-top: 0.5rem;
			}
		}
	}
	.bottom-wrapper {
		height: 500px;
	}
	.start-form-txt {
		width: 100%;
		position: absolute;
		text-align: center;
		bottom: 120px;
		left: 0;
	}
	.price-txt {
		width: 100%;
		position: absolute;
		text-align: center;
		bottom: 80px;
		left: 0;
	}
	.button-wrapper {
		position: absolute;
		bottom: 20px;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		button {
			width: 100%;
			margin-right: 1.5rem;
			margin-left: 1.5rem;
			text-align: center;
			display: inline-block;
			@media only screen and (max-width: 1400px) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}
	}
`;

export default Wrapper;
