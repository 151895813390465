import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Mobile, MiniMobile } from '../../_Variable/var';

const Wrapper = styled.div`
	&.pDefault {
		font-family: Nunito, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 150%;
		color: #4f4f4f;
		span.bigger {
			font-size: 2rem;
			font-weight: bold;
			@media only screen and (max-width: ${Mobile}) {
				font-size: 1.75rem;
			}
			@media only screen and (max-width: ${MiniMobile}) {
				font-size: 1.5rem;
			}
		}
		span.bolder {
			font-weight: bold;
		}
	}
`;

const PriceParagraph = ({ children, className }) => {
	return <Wrapper className={className || 'pDefault'}>{children}</Wrapper>;
};

PriceParagraph.defaultProps = {
	className: '',
};

PriceParagraph.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PriceParagraph;
