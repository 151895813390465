import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tablet } from '../../_Variable/var';

const Wrapper = styled.h1`
	&.mediumTextDefault {
		font-family: Montserrat, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 2.875rem;
		line-height: 4rem;
		letter-spacing: 0.0625rem;
		color: #4f4f4f;
		margin: 0;
		padding: 0;
		@media only screen and (max-width: ${Tablet}) {
			font-size: 1.875rem;
			line-height: 140%;
		}
	}
`;

const MediumText = ({ children, className }) => {
	return <Wrapper className={className || 'mediumTextDefault'}>{children}</Wrapper>;
};

MediumText.defaultProps = {
	className: '',
};

MediumText.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default MediumText;
