import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { SVGRender } from '../../../../helper/image';
import H1 from '../../../atoms/Label/Headers/H1/clear';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import PriceParagraph from '../../../atoms/Label/Price';
import ButtonPrimary from '../../../atoms/Button/Primary';
import { Wrapper } from './style';

const PriceList = ({
	title,
	sub,
	lists,
	price,
	isFullPrice,
	txtButton,
	withLastIco,
	withStartFrom,
	onButtonClick,
	enPrefix,
}) => {
	const intl = useIntl();
	const renderButton = () => {
		return (
			<ButtonPrimary
				onClick={() => {
					onButtonClick();
				}}
			>
				{txtButton}
			</ButtonPrimary>
		);
	};
	return (
		<Wrapper className="price-list-item">
			<div className="price-header">
				<div className="price-header__left">
					<H1 className="h1Default price-title">{title}</H1>
					<Paragraph className="pDefault price-sub">{sub}</Paragraph>
				</div>
				<div className="price-header__right">
					{withStartFrom && (
						<PriceParagraph className="pDefault txt-start-from">
							{intl.formatMessage({ id: `pricing.start_from` })}
						</PriceParagraph>
					)}

					{isFullPrice ? (
						<PriceParagraph className="pDefault txt-price">
							<span className="bolder">{price}</span>
						</PriceParagraph>
					) : (
						<PriceParagraph className="pDefault txt-price">
							<span>Starting from: </span>
							<span className="bolder">{enPrefix ? <>US$</> : <>Rp</>}</span>
							<span className="bigger">{price}</span>
							<span className="bolder">/</span>
							{enPrefix ? <>Month</> : <>Bulan</>}
						</PriceParagraph>
					)}

					<div className="btn-right-wrapper">{renderButton()}</div>
				</div>
			</div>

			<ul>
				{lists.map((v, i) => {
					if (i + 1 === lists.length && withLastIco) {
						return (
							<li>
								{SVGRender('https://seiturju.sirv.com/Chatbiz/arrow-back-orange.svg', 'arrow-back-orange')}
								<Paragraph>{v}</Paragraph>
							</li>
						);
					}
					return (
						<li>
							{SVGRender('https://seiturju.sirv.com/Chatbiz/done_outlined-blue.svg', 'arrow-back-orange')}

							<Paragraph>{v}</Paragraph>
						</li>
					);
				})}
			</ul>
			<div className="bottom-wrapper">
				{withStartFrom && (
					<PriceParagraph className="pDefault start-form-txt">
						{intl.formatMessage({ id: `pricing.start_from` })}
					</PriceParagraph>
				)}

				{isFullPrice ? (
					<PriceParagraph className="pDefault price-txt">
						<span className="bolder">{price}</span>
					</PriceParagraph>
				) : (
					<PriceParagraph className="pDefault price-txt">
						<span>Starting from: </span>
						<span className="bolder">{enPrefix ? <>US$</> : <>Rp</>}</span>
						<span className="bigger">{price}</span>
						<span className="bolder">/</span>
						{enPrefix ? <>Month</> : <>Bulan</>}
					</PriceParagraph>
				)}
				<div className="button-wrapper">{renderButton()}</div>
			</div>
		</Wrapper>
	);
};

PriceList.defaultProps = {
	withLastIco: false,
	isFullPrice: false,
	withStartFrom: false,
};

PriceList.propTypes = {
	title: PropTypes.string.isRequired,
	sub: PropTypes.string.isRequired,
	lists: PropTypes.arrayOf(PropTypes.string).isRequired,
	withLastIco: PropTypes.bool,
	price: PropTypes.string.isRequired,
	isFullPrice: PropTypes.bool,
	txtButton: PropTypes.string.isRequired,
	withStartFrom: PropTypes.bool,
	onButtonClick: PropTypes.func.isRequired,
	enPrefix: PropTypes.string.isRequired,
};

export default PriceList;
