/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

import MediumTextH1 from '../../../atoms/Label/MediumText/H1';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import PriceList from '../../../molecules/v2/PriceList';
// import ButtonPrimary from '../../../atoms/Button/Primary';
// import { SVGRender } from '../../../../helper/image';
import { Wrapper } from './style';

const Pricing = ({ onSelectedPackage, enPrefix }) => {
	const intl = useIntl();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const PriceList1 = () => {
		return (
			<PriceList
				title={intl.formatMessage({ id: `pricing.list_1.title` })}
				sub={intl.formatMessage({ id: `pricing.list_1.sub` })}
				lists={[
					intl.formatMessage({ id: `pricing.list_1.list_1` }),
					intl.formatMessage({ id: `pricing.list_1.list_2` }),
					intl.formatMessage({ id: `pricing.list_1.list_3` }),
					intl.formatMessage({ id: `pricing.list_1.list_4` }),
					intl.formatMessage({ id: `pricing.list_1.list_5` }),
					intl.formatMessage({ id: `pricing.list_1.list_6` }),
				]}
				isFirstList
				price={intl.formatMessage({ id: `pricing.list_1.price` })}
				txtButton={intl.formatMessage({ id: `pricing.list_1.button` })}
				onButtonClick={() => {
					onSelectedPackage('Basic');
				}}
				enPrefix={enPrefix}
			/>
		);
	};

	const PriceList2 = () => {
		return (
			<PriceList
				title={intl.formatMessage({ id: `pricing.list_2.title` })}
				sub={intl.formatMessage({ id: `pricing.list_2.sub` })}
				lists={[
					intl.formatMessage({ id: `pricing.list_2.list_1` }),
					intl.formatMessage({ id: `pricing.list_2.list_2` }),
					intl.formatMessage({ id: `pricing.list_2.list_3` }),
					intl.formatMessage({ id: `pricing.list_2.list_4` }),
				]}
				price={intl.formatMessage({ id: `pricing.list_2.price` })}
				txtButton={intl.formatMessage({ id: `pricing.list_2.button` })}
				onButtonClick={() => {
					onSelectedPackage('Enterprise');
				}}
				isFullPrice
				enPrefix={enPrefix}
			/>
		);
	};

	return (
		<Wrapper className="banner-home">
			<div className="container">
				<div className="row rowBannerHome">
					<div className="col-12">
						<div className="textWrapper">
							<MediumTextH1 className="topIndex mediumTextDefault">
								{intl.formatMessage({ id: `pricing.title` })}
							</MediumTextH1>
							<Paragraph className="topIndex pDefault">
								{intl.formatMessage({ id: `pricing.sub` })}
							</Paragraph>
						</div>
					</div>
				</div>
				<div className="row rowPriceList">
					<div className="col-1 col-sm-1 col-md-1 col-xl-1" />
					<div className="col-5 col-sm-5 col-md-5 col-xl-5">
						<PriceList1 />
					</div>
					<div className="col-5 col-sm-5 col-md-5 col-xl-5">
						<PriceList2 />
					</div>
					<div className="col-1 col-sm-1 col-md-1 col-xl-1" />
				</div>
			</div>

			<div className="quarterCircle" />

			<div className="list-on-mobile">
				<div className="container container-mobile">
					<div className="list-on-mobile-wrapper">
						<PriceList1 />
						<PriceList2 />
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

Pricing.propTypes = {
	onSelectedPackage: PropTypes.func.isRequired,
	enPrefix: PropTypes.string.isRequired,
};

export default Pricing;
