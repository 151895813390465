/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
// import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

// import { Helmet } from 'react-helmet';
import 'lazysizes';
import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.css';
import '@fontsource/nunito';

import Menu from '../../../organisms/v2/Menu';
import Pricing from '../../../organisms/v2/Pricing';
import Footer from '../../../organisms/Footer';
import ModalChatbiz from '../../../organisms/Modal/ModalChatbiz';
import withDefautlHoc from '../../../_hoc/global';

import withIntl from '../../../../helper/hoc/withIntl';

const PricingPage = ({ enPrefix }) => {
	const [selectedPackage, setSelectedPackage] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	// const intl = useIntl();

	return (
		<>
			<Menu
				enPrefix={enPrefix}
				onDemoClick={() => {
					setSelectedPackage('');
					setModalOpen(true);
				}}
			/>

			<Pricing
				onSelectedPackage={data => {
					setSelectedPackage(data);
					setModalOpen(true);
				}}
				enPrefix={enPrefix}
			/>
			<Footer enPrefix={enPrefix} />
			{modalOpen && (
				<ModalChatbiz
					isModalOpen={modalOpen}
					selectedPackage={selectedPackage}
					onClose={() => {
						setModalOpen(false);
					}}
					onSuccess={() => {
						setModalOpen(false);
					}}
				/>
			)}
		</>
	);
};

PricingPage.propTypes = {
	enPrefix: PropTypes.string.isRequired,
};

export default withIntl(withDefautlHoc(PricingPage));
